import globalStyles from '@global/global.css'
import { Barlow } from '@next/font/google'
import * as Fathom from 'fathom-client'
import { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { useRouter } from 'next/router'
import 'normalize.css'
import { NuqsAdapter } from 'nuqs/adapters/next/pages'
import { useEffect } from 'react'

// If loading a variable font, you don't need to specify the font weight
const barlow = Barlow({
    weight: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
    subsets: ['latin']
})

const Layout = dynamic(() => import('@components/layout/Layout'), {
    ssr: false
})

const App = ({ Component, pageProps }: AppProps) => {
    const router = useRouter()

    useEffect(() => {
        Fathom.load(process.env.FATHOM_SITE_ID || '', {
            includedDomains: ['staging.statemints.com', 'statemints.com']
        })

        function onRouteChangeComplete() {
            Fathom.trackPageview()
        }
        // Record a pageview when route changes
        router.events.on('routeChangeComplete', onRouteChangeComplete)

        // Unassign event listener
        return () => {
            router.events.off('routeChangeComplete', onRouteChangeComplete)
        }
    }, [router.events])

    globalStyles()
    return (
        <>
            <style jsx global>{`
                html {
                    font-family: ${barlow.style.fontFamily};
                }
            `}</style>
            <Head>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
                />
            </Head>
            <NuqsAdapter>
                <Layout>
                    <Component {...pageProps} />
                </Layout>
            </NuqsAdapter>
        </>
    )
}

export default App
