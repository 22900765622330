import { globalCss } from 'ui/theme/stitches.config'

const globalStyles = globalCss({
    '*,*::before,*::after': {
        boxSizing: 'border-box'
    },
    button: {
        backgroundColor: 'transparent',
        color: 'inherit',
        borderWidth: '0',
        padding: '0',
        cursor: 'pointer'
    },
    figure: {
        margin: '0'
    },
    'input::-moz-focus-inner': {
        border: '0',
        padding: '0',
        margin: '0'
    },
    'ul,ol,dd': {
        margin: '0',
        padding: '0',
        listStyle: 'none'
    },
    'h1,h2,h3,h4,h5,h6': {
        margin: '0',
        fontSize: 'inherit',
        fontWeight: 'inherit'
    },
    a: {
        textDecoration: 'none',
        color: 'inherit'
    },
    cite: {
        fontStyle: 'normal'
    },
    fieldset: {
        borderWidth: '0',
        padding: '0',
        margin: '0'
    },
    html: {
        fontSize: '1px'
    },
    body: {
        fontSize: '16rem',
        fontDisplay: 'optional',
        color: '$ivory',
        display: 'block'
    },
    'main.dashboard-background': {
        background:
            'linear-gradient(180deg, #2C3135 0%, #202225 100%), linear-gradient(180deg, #1D2530 0%, #2A3647 100%)'
    },
    '.sr-only': {
        position: 'absolute',
        width: '1px',
        height: '1px',
        padding: '0',
        margin: '-1px',
        overflow: 'hidden',
        clip: 'rect(0, 0, 0, 0)',
        border: '0'
    }
})

export default globalStyles
